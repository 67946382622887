<template>
  <div class="elv-dashboard-chart-widget-chart-of-account-select">
    <div class="elv-dashboard-chart-widget-chart-of-account-select-show-info" @click="onChangeChartOfAccountStatus">
      <div class="elv-dashboard-chart-widget-chart-of-account-select-show-info-item">
        <span>{{
          selectChartOfAccountInfo.length
            ? selectChartOfAccountInfo.map((item) => (locale === 'en' ? item?.name : item?.nameCN)).join(', ')
            : t('common.select')
        }}</span>
      </div>
      <SvgIcon name="arrow-down-line" width="12" height="12" :style="{ transform: rotate }" :fill="'#636B75'" />
    </div>
    <el-cascader
      v-bind="$attrs"
      ref="chartOfAccountSelectRef"
      :options="currentChartOfAccountOptions"
      :props="selectProps"
      collapse-tags
      collapse-tags-tooltip
      clearable
      tag-type="info"
      separator=","
      :max-collapse-tags="2"
      :placeholder="t('common.pleaseChoose')"
      popper-class="elv-dashboard-chart-widget-chart-of-account-select-popper"
    />
  </div>
</template>

<script setup lang="ts">
import { useEntityStore } from '@/stores/modules/entity'

const attrs = useAttrs()
const { t, locale } = useI18n()
const entityStore = useEntityStore()

const rotate = ref('rotate(0deg)')
const selectChartOfAccountInfo = ref<any[]>([])
const chartOfAccountSelectRef = useTemplateRef('chartOfAccountSelectRef')

const selectProps = computed(() => {
  return {
    multiple: true,
    emitPath: false,
    value: 'chartOfAccountId',
    label: localStorage.getItem('language') === 'en' ? 'name' : 'nameCN',
    children: 'list'
  }
})

const currentChartOfAccountOptions = computed(() => {
  const filterChartOfAccountList = entityStore.chartOfAccountOriginList.map((item) => {
    const updatedItem = { ...item }
    if (!updatedItem?.nameCN) {
      updatedItem.nameCN = updatedItem.name
    }
    updatedItem.list = updatedItem?.list?.map((childItem) => {
      const updatedChildItem = { ...childItem }
      if (!updatedChildItem.nameCN) {
        updatedChildItem.nameCN = updatedChildItem.name
      }
      return updatedChildItem
    })
    return updatedItem
  })
  return filterChartOfAccountList
})

const onChangeChartOfAccount = (selectList: any) => {
  if (!selectList) return
  selectChartOfAccountInfo.value = currentChartOfAccountOptions.value.flatMap((category) =>
    category.list.filter((item) => selectList.includes(item.chartOfAccountId))
  )
}

watch(
  () => attrs?.modelValue,
  (newValue) => {
    onChangeChartOfAccount(newValue)
  },
  {
    immediate: true
  }
)

const onChangeChartOfAccountStatus = () => {
  chartOfAccountSelectRef.value?.togglePopperVisible()
}
</script>

<style lang="scss" scoped>
.elv-dashboard-chart-widget-chart-of-account-select {
  max-width: 106px;
  height: 15px;
  display: flex;
  align-items: center;
  position: relative;

  .elv-dashboard-chart-widget-chart-of-account-select-show-info {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    max-width: 106px;
    position: relative;

    &-item {
      display: flex;
      max-width: calc(100% - 16px);
      align-items: center;
      height: 15px;
      font-family: 'Plus Jakarta Sans';
      font-size: 12px;
      font-weight: 400;
      color: #636b75;
      position: relative;

      span {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    svg {
      margin-left: 4px;
    }
  }

  :deep(.el-cascader) {
    height: 15px;
    position: absolute;
    left: 0px;

    .el-input {
      display: none;
    }

    .el-cascader__tags {
      display: none;
    }
  }
}
</style>
<style lang="scss">
.elv-dashboard-chart-widget-chart-of-account-select-popper {
  font-family: 'Plus Jakarta Sans';

  .el-cascader-menu {
    &:first-child {
      width: 179px;

      .el-cascader-node__label {
        font-weight: 600;
        font-size: 12px;
        color: #1e2024;
      }
    }
  }

  .el-cascader-menu__list {
    padding: 0px;

    li {
      height: 32px;
      padding: 0px 8px;

      .el-cascader-node__label {
        height: 32px;
        font-family: 'Plus Jakarta Sans';
        font-weight: 500;
        font-size: 14px;
        color: #606266;
        padding-right: 15px;
      }

      svg {
        path {
          fill: #dce1e6;
        }
      }
    }
  }
}
</style>
