import { $t } from '@/i18n/index'
import { DashboardChartTypes } from '#/DashboardTypes'

export const DashboardModuleType = {
  treasury: {
    label: $t('project.nav.treasury'),
    value: 'TREASURY_BALANCE'
  },
  reports: {
    label: $t('common.reports'),
    value: 'REPORT'
  },
  ledger: {
    label: $t('common.ledger'),
    value: 'LEDGER'
  }
}

export const datePeriodList = [
  {
    label: $t('common.day'),
    value: 'DAY'
  },
  {
    label: $t('common.Month'),
    value: 'MONTH'
  },
  {
    label: $t('common.quarter'),
    value: 'QUARTER'
  },
  {
    label: $t('common.year'),
    value: 'YEAR'
  }
]

export const timeGranularity = [
  {
    label: $t('project.dashboard.last30Days'),
    value: 'LAST_30_DAYS'
  },
  {
    label: $t('project.dashboard.thisMonth'),
    value: 'THIS_MONTH'
  },
  {
    label: $t('project.dashboard.thisQuarter'),
    value: 'THIS_QUARTER'
  }
]

export const viewByList = [
  {
    label: $t('project.dashboard.viewByQuarter'),
    value: 'VIEW_BY_QUARTER'
  },
  {
    label: $t('project.dashboard.viewByMonth'),
    value: 'VIEW_BY_MONTH'
  },
  {
    label: $t('project.dashboard.viewByDay'),
    value: 'VIEW_BY_DAY'
  }
]

export const TreasurySummaryIndicatorList = [
  {
    label: $t('project.dashboard.totalBalance'),
    value: 'TOTAL_BALANCE',
    showCurrency: true,
    showViewBy: false,
    showTrend: false,
    showDistribution: false
  },
  {
    label: $t('project.dashboard.fiatBalance'),
    value: 'FIAT_BALANCE',
    showCurrency: true,
    showViewBy: false,
    showTrend: false,
    showDistribution: false
  },
  {
    label: $t('project.dashboard.cryptoBalance'),
    value: 'CRYPTO_BALANCE',
    showCurrency: true,
    showViewBy: false,
    showTrend: false,
    showDistribution: false
  }
]

export const LedgerMetricIndicatorList = [
  {
    label: $t('common.ledger'),
    value: 'TOTAL_AMOUNT',
    title: $t('project.dashboard.ledgerDistribution'),
    showCurrency: false,
    showViewBy: false,
    showTrend: false,
    showDistribution: false
  }
]

export const ReportSummaryIndicatorList = [
  {
    label: $t('project.dashboard.totalAssets'),
    value: 'TOTAL_ASSET',
    showCurrency: true,
    showViewBy: true,
    showTrend: false,
    showDistribution: false
  },
  {
    label: $t('project.dashboard.totalLiabilities'),
    value: 'TOTAL_LIABILITY',
    showCurrency: true,
    showViewBy: true,
    showTrend: false,
    showDistribution: false
  },
  {
    label: $t('project.dashboard.totalEquity'),
    value: 'TOTAL_EQUITY',
    showCurrency: false,
    showViewBy: true,
    showTrend: false,
    showDistribution: false
  },
  {
    label: $t('project.dashboard.revenue'),
    value: 'TOTAL_REVENUE',
    showCurrency: false,
    showViewBy: true,
    showTrend: true,
    showDistribution: false
  },
  {
    label: $t('project.dashboard.expense'),
    value: 'TOTAL_EXPENSE',
    showCurrency: false,
    showViewBy: true,
    showTrend: true,
    showDistribution: false
  },
  {
    label: $t('project.dashboard.profit'),
    value: 'TOTAL_NET_INCOME',
    showCurrency: false,
    showViewBy: false,
    showTrend: true,
    showDistribution: false
  }
]

export const DashboardChartType: DashboardChartTypes = {
  lineChart: {
    type: 'LINE_CHART',
    showType: 'LINE',
    icon: 'chart-line-gray',
    dashboardType: ['TREND', 'LINE'],
    changeChartType: 'BAR_VERTICAL_CHART'
  },
  barVerticalChart: {
    type: 'BAR_VERTICAL_CHART',
    showType: 'BAR',
    icon: 'chart-column',
    dashboardType: ['TREND', 'BAR'],
    changeChartType: 'LINE_CHART'
  },
  barHorizontalChart: {
    type: 'BAR_HORIZONTAL_CHART',
    showType: 'BAR',
    icon: 'chart-row',
    dashboardType: ['DISTRIBUTION', 'BAR'],
    changeChartType: 'PIE_CHART'
  },
  pieChart: {
    type: 'PIE_CHART',
    showType: 'PIE',
    icon: 'chart-pie',
    dashboardType: ['DISTRIBUTION', 'PIE'],
    changeChartType: 'BAR_HORIZONTAL_CHART'
  }
}

export const DashboardChartColorType = [
  '#124BDD',
  '#7242F2',
  '#10C4EC',
  '#EC6F10',
  '#FFBB0E',
  '#2F64EE',
  '#8961F4',
  '#36CFF1',
  '#F18736',
  '#FFC636'
]
