<template>
  <div class="elv-dashboard-module-nav">
    <nav class="elv-dashboard-module-nav-header" @mouseover="isHovered = true" @mouseleave="isHovered = false">
      <DashBoardModuleMoveIcon
        v-show="isHovered && !isUnEnabledMoveModule"
        :disabledMoveUp="props.moduleIndex === 0"
        :disabledMoveDown="props.moduleIndex >= props.moduleListInfo.length - 1"
        :moveUpModule="upMoveModule"
        :moveDownModule="downMoveModule"
      />
      <ChartMenus
        ref="dashboardMenusRef"
        :title="t(props.moduleTypeInfo.label)"
        :moduleTypeInfo="props.moduleTypeInfo"
        @onChangDateTime="onChangDateTime"
        @onChangeLedgerDateTime="onChangeLedgerDateTime"
      />
      <el-button v-if="disabledAddBtn" class="elv-dashboard-module-nav-add-chart-btn" round @click="addNewChart">
        <SvgIcon name="add--alt" :width="16" :height="16" />
        {{ t('button.addChart') }}</el-button
      >
    </nav>
    <div v-if="showSummaryList" v-loading="updateChartListLoading" class="elv-dashboard-module-nav-body">
      <div v-if="showSummaryList && !!summaryList.length" class="elv-dashboard-module-nav-summary">
        <SummaryItem
          v-for="item in summaryList"
          :key="item.chartId"
          :summaryData="item"
          :summaryType="props.moduleTypeInfo.value"
          @onUpdateSummaryList="onUpdateChartList"
        />
      </div>
      <div
        v-if="!!sortableChartList.length"
        class="elv-dashboard-module-nav-chart-list"
        :aria-label="moduleTypeInfo?.value"
      >
        <CharItem
          v-for="item in sortableChartList"
          :key="item.chartId"
          :dashboardModuleType="props.moduleTypeInfo.value"
          :chartData="item"
          :changePeriodStatus="changePeriodStatus"
          @onDeleteChartItem="onDeleteChartItem"
          @onEditChartItem="onEditChartItem"
        />
      </div>
    </div>
    <div v-else v-loading="updateChartListLoading" class="elv-dashboard-module-nav-empty">
      <SvgIcon name="dashboard-empty" :width="35" :height="35" fill="#DDE1E6" />
      <p>
        {{ t('common.noData') }}<span @click="onJumpLink">{{ moduleEmptyJumpLinkText }}</span>
      </p>
    </div>
    <AddChartWidgetDialog
      ref="addChartWidgetDialogRef"
      :dashboardModuleType="props.moduleTypeInfo.value"
      @onClickConfirmHandler="onSaveChartItem"
    />

    <ElvMessageBox
      ref="deleteMessageBoxRef"
      :showCancelButton="true"
      :cancelButtonText="t('button.cancel')"
      :confirmButtonText="t('button.delete')"
      :loading="deleteLoading"
      :title="t('title.deleteChart')"
      @onConfirmEvent="onConfirmDeleteEvent"
      @onCancelEvent="onCheckDeleteMessageBox"
    >
      <template #content>
        <span class="elv-confirm-info-header__title">
          {{ t('message.deleteChartMessage', { name: currentChartData?.name }) }}
        </span>
      </template>
    </ElvMessageBox>
  </div>
</template>

<script setup lang="ts">
import CharItem from './CharItem.vue'
import { ElMessage } from 'element-plus'
import ChartMenus from './ChartMenus.vue'
import SummaryItem from './SummaryItem.vue'
import DashboardApi from '@/api/DashboardApi'
import { useEntityStore } from '@/stores/modules/entity'
import { DashboardModuleType } from '@/config/dashboard'
import { DashboardChartItemTypes } from '#/DashboardTypes'
import AddChartWidgetDialog from './AddChartWidgetDialog.vue'
import { useDashboardStore } from '@/stores/modules/dashboard'
import DashBoardModuleMoveIcon from './DashBoardModuleMoveIcon.vue'

const props = defineProps({
  moduleTypeInfo: {
    type: Object,
    required: true
  },
  moduleIndex: {
    type: Number,
    required: true
  },
  moduleListInfo: {
    type: Array,
    default: () => []
  }
})

const emit = defineEmits(['onUpMoveModule', 'onDownMoveModule'])

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const entityStore = useEntityStore()
const dashboardStore = useDashboardStore()

const isHovered = ref(false)
const deleteLoading = ref(false)
const changePeriodStatus = ref(false)
const updateChartListLoading = ref(false)
const dashboardMenusRef = useTemplateRef('dashboardMenusRef')
const deleteMessageBoxRef = useTemplateRef('deleteMessageBoxRef')
const addChartWidgetDialogRef = useTemplateRef('addChartWidgetDialogRef')
const currentChartData = ref<DashboardChartItemTypes>({} as DashboardChartItemTypes)

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

const summaryList = computed(() => {
  if (props.moduleTypeInfo.value === DashboardModuleType.treasury.value) {
    return dashboardStore.treasuryData.fixedChartList
  }
  if (props.moduleTypeInfo.value === DashboardModuleType.reports.value) {
    return dashboardStore.reportsData.fixedChartList
  }
  return []
})

const sortableChartList = computed(() => {
  if (props.moduleTypeInfo.value === DashboardModuleType.treasury.value) {
    return dashboardStore.treasuryData.sortableChartList
  }
  if (props.moduleTypeInfo.value === DashboardModuleType.reports.value) {
    return dashboardStore.reportsData.sortableChartList
  }
  if (props.moduleTypeInfo.value === DashboardModuleType.ledger.value) {
    return dashboardStore.ledgerData
  }
  return []
})

const isUnEnabledMoveModule = computed(() => {
  return (
    ['MEMBER', ''].includes(currentEntityPermission.value?.role) && !currentEntityPermission.value?.dashboard?.update
  )
})

const showSummaryList = computed(() => {
  if (props.moduleTypeInfo.value === DashboardModuleType.ledger.value) {
    return sortableChartList.value.length > 0
  }
  return (
    !!summaryList.value.length &&
    summaryList.value.some((summary) => summary?.chartValueList?.[0]?.totalAmountFC !== null)
  )
})

const disabledAddBtn = computed(() => {
  return (
    !(
      ['MEMBER', ''].includes(currentEntityPermission.value?.role) && !currentEntityPermission.value?.dashboard?.update
    ) && showSummaryList.value
  )
})

const moduleEmptyJumpLinkText = computed(() => {
  if (props.moduleTypeInfo?.value === 'TREASURY_BALANCE') {
    return t('button.addAccountsInTreasury')
  }
  if (props.moduleTypeInfo?.value === 'REPORT') {
    return t('button.generateReportsInReports')
  }
  return t('button.addChart')
})

const upMoveModule = () => {
  emit('onUpMoveModule', props.moduleIndex)
}

const downMoveModule = () => {
  emit('onDownMoveModule', props.moduleIndex)
}

/**
 * 添加新的Chart表格
 */
const addNewChart = () => {
  addChartWidgetDialogRef.value?.changeDialogStatus()
}

const onJumpLink = () => {
  if (props.moduleTypeInfo.value === DashboardModuleType.ledger.value) {
    addNewChart()
  } else {
    router.push({
      name: props.moduleTypeInfo?.value === 'TREASURY_BALANCE' ? 'entity-accounts-treasury' : 'reports'
    })
  }
}

/**
 * 编辑Chart表格
 * @param chartData 图表数据
 */
const onEditChartItem = (editData: any) => {
  addChartWidgetDialogRef.value?.changeDialogStatus(editData?.data, editData?.timeRange)
}

/**
 * 更新Chart表格
 */
const onUpdateChartList = async () => {
  updateChartListLoading.value = true
  try {
    if (props.moduleTypeInfo.value === DashboardModuleType.treasury.value) {
      await dashboardStore.fetchTreasuryData(entityId.value)
    }
    if (props.moduleTypeInfo.value === DashboardModuleType.reports.value) {
      const params = dashboardStore.getCurrentReportFilterInfo(entityId.value)
      await dashboardStore.fetchReportsData(entityId.value, params)
    }
    if (props.moduleTypeInfo.value === DashboardModuleType.ledger.value) {
      const params = dashboardStore.getCurrentLedgerFilterInfo(entityId.value)
      await dashboardStore.fetchLedgerData(entityId.value, params)
    }
  } catch (error: any) {
    ElMessage.error(error.message)
  } finally {
    updateChartListLoading.value = false
  }
}

/**
 * 保存Chart表格
 */
const onSaveChartItem = async () => {
  onUpdateChartList()
}

/**
 * @description: 切换删除弹窗状态
 */
const onCheckDeleteMessageBox = () => {
  deleteMessageBoxRef.value?.onCheckMessageBoxDialog()
}

/**
 * 删除Chart表格
 * @param chartId 图表数据
 */
const onDeleteChartItem = (chartData: DashboardChartItemTypes) => {
  currentChartData.value = chartData
  onCheckDeleteMessageBox()
}

/**
 * @description: 删除规则确认
 */
const onConfirmDeleteEvent = async () => {
  try {
    deleteLoading.value = true
    await DashboardApi.deleteChartData(entityId.value, currentChartData.value?.chartId)
    ElMessage.success(t('message.deleteSuccess'))
    onCheckDeleteMessageBox()
    onUpdateChartList()
  } catch (error: any) {
    ElMessage.error(error.message)
  } finally {
    deleteLoading.value = false
  }
}

/**
 * Ledger模块日期筛选变化时触发
 */
const onChangeLedgerDateTime = () => {
  onUpdateChartList()
}

/**
 * 日期筛选变化时触发
 * @param data 日期
 */
const onChangDateTime = async (data: any) => {
  const changePeriodInfo = dashboardStore.getCurrentReportFilterInfo(entityId.value)
  if (!changePeriodInfo) {
    changePeriodStatus.value = !changePeriodStatus.value
  }
  if (changePeriodInfo?.period !== data?.period) {
    changePeriodStatus.value = !changePeriodStatus.value
  }
  onUpdateChartList()
}
</script>

<style lang="scss" scoped>
.elv-dashboard-module-nav {
  margin-top: 25px;

  .elv-dashboard-module-nav-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    overflow: visible;
    padding-left: 18px;

    .elv-dashboard-module-nav-add-chart-btn {
      width: 102px;
      height: 32px;
      border: 1px solid #c8d6fa;
      color: #1753eb;
      font-size: 13px;
      font-weight: 500;

      svg {
        margin-right: 6px;
      }
    }
  }

  .elv-dashboard-module-nav-summary {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .elv-dashboard-module-nav-chart-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    margin-top: 16px;
  }

  .elv-dashboard-module-nav-body {
    padding-left: 18px;
  }

  .elv-dashboard-module-nav-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 231px;
    margin-top: 16px;
    color: #636b75;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 8px;
    background: #f9fafb;
    margin-left: 18px;

    p {
      margin-top: 16px;
    }

    span {
      color: #1753eb;
      font-family: 'Plus Jakarta Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-decoration-line: underline;
      cursor: pointer;
      margin-left: 6px;
    }
  }
}
</style>
