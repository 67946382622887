import DashboardApi from '@/api/DashboardApi'
import { DashboardModuleType } from '@/config/dashboard'
import {
  CurrencyOptionType,
  DashboardChartItemTypes,
  DashboardChartListTypes,
  DashboardChartsFilterTypes
} from '#/DashboardTypes'
import ConfigApi from '@/api/ConfigApi'
import { cloneDeep } from 'lodash-es'
import LedgerApi from '@/api/LedgerApi'

export const useDashboardStore = defineStore({
  id: 'dashboardStore',
  state: () => {
    const dashboardState: {
      treasuryData: DashboardChartListTypes
      reportsData: DashboardChartListTypes
      ledgerData: DashboardChartItemTypes[]
      reportChartsFilterInfo: DashboardChartsFilterTypes[]
      ledgerChartsFilterInfo: DashboardChartsFilterTypes[]
      dashboardCurrencyList: Array<string>
      recommendCurrencyList: Array<CurrencyOptionType>
      auxiliaryTypeList: Array<any>
    } = {
      treasuryData: {
        fixedChartList: [] as Array<DashboardChartItemTypes>,
        sortableChartList: [] as Array<DashboardChartItemTypes>
      },
      reportsData: {
        fixedChartList: [] as Array<DashboardChartItemTypes>,
        sortableChartList: [] as Array<DashboardChartItemTypes>
      },
      ledgerData: [],
      reportChartsFilterInfo: [],
      ledgerChartsFilterInfo: [],
      dashboardCurrencyList: [],
      recommendCurrencyList: [],
      auxiliaryTypeList: []
    }
    return dashboardState
  },
  actions: {
    /**
     * 获取treasury数据
     * @param {number} entityId 主体id
     */
    async fetchTreasuryData(entityId: string) {
      try {
        const response = await DashboardApi.getTreasuryData(entityId)
        this.treasuryData = response.data
        return response.data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取reports数据
     * @param {string} entityId 主体id
     * @param {object} params 请求参数
     * @property {string} period 时间范围
     * @property {string[]} dateRange 时间段
     */
    async fetchReportsData(entityId: string, params: any) {
      try {
        const response = await DashboardApi.getReportsData(entityId, params)
        this.reportsData = response.data
        return response.data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取Ledger Chart数据
     * @param {string} entityId 主体id
     * @param {object} params 请求参数
     * @property {string[]} dateRange 时间段
     */
    async fetchLedgerData(entityId: string, params: any) {
      try {
        const response = await DashboardApi.getLedgerChardListData(entityId, params)
        this.ledgerData = response.data
        return response.data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 替换Chart数据
     */
    async updateChartData(dashboardModuleType: string, chartData: DashboardChartItemTypes) {
      const { chartId } = chartData
      if (dashboardModuleType === DashboardModuleType.treasury.value) {
        const chartList = this.treasuryData.sortableChartList
        const chartIndex = chartList.findIndex((item: DashboardChartItemTypes) => item.chartId === chartId)
        if (chartIndex > -1) {
          chartList[chartIndex] = chartData
        }
      } else if (dashboardModuleType === DashboardModuleType.reports.value) {
        const chartList = this.treasuryData.sortableChartList
        const chartIndex = chartList.findIndex((item: DashboardChartItemTypes) => item.chartId === chartId)
        if (chartIndex > -1) {
          chartList[chartIndex] = chartData
        }
      }
    },
    /**
     * 保存Report Charts Filter信息
     * @param {object} filterInfo 筛选信息
     * @property {string} period 时间段
     * @property {string} timeRange 时间范围
     */
    saveReportChartsFilterInfo(filterInfo: DashboardChartsFilterTypes) {
      const findFilterInfo = this.reportChartsFilterInfo.find((item) => item.entityId === filterInfo.entityId)
      const modifiedFilterInfo = {
        ...filterInfo,
        period: filterInfo.period?.toLocaleUpperCase()
      }
      if (findFilterInfo) {
        findFilterInfo.period = modifiedFilterInfo.period
        findFilterInfo.dateRange = modifiedFilterInfo.dateRange
      } else {
        this.reportChartsFilterInfo.push(modifiedFilterInfo)
      }
    },
    /**
     * 根据给定的 entityId 删除 reportChartsFilterInfo 数组中的对应项
     * @param entityId 需要删除的项目的 entityId
     */
    deleteReportChartsFilterInfo(entityId: string) {
      const findIndex = this.reportChartsFilterInfo.findIndex((item) => item.entityId === entityId)
      if (findIndex > -1) {
        this.reportChartsFilterInfo.splice(findIndex, 1)
      }
    },
    /**
     * 保存Ledger Charts Filter信息
     * @param {object} filterInfo 筛选信息
     * @property {string} timeRange 时间范围
     */
    saveLedgerChartsFilterInfo(filterInfo: DashboardChartsFilterTypes) {
      const findFilterInfo = this.ledgerChartsFilterInfo.find((item) => item.entityId === filterInfo.entityId)
      const modifiedFilterInfo = {
        ...filterInfo
      }
      if (findFilterInfo) {
        findFilterInfo.dateRange = modifiedFilterInfo.dateRange
      } else {
        this.ledgerChartsFilterInfo.push(modifiedFilterInfo)
      }
    },
    /**
     * 根据给定的 entityId 删除 ledgerChartsFilterInfo 数组中的对应项
     * @param entityId 需要删除的项目的 entityId
     */
    deleteLedgerChartsFilterInfo(entityId: string) {
      const findIndex = this.ledgerChartsFilterInfo.findIndex((item) => item.entityId === entityId)
      if (findIndex > -1) {
        this.ledgerChartsFilterInfo.splice(findIndex, 1)
      }
    },
    /**
     * 获取币种信息
     * @param {object} params 获取币种列表参数
     * @property {boolean} recommend 是否推荐币种列表
     * @property {string} entityId 主体ID
     * @property {string[]} keywords 币种关键字
     */
    async fetchCurrencyList(params: any) {
      const searchList = await ConfigApi.searchCurrencyList(params)
      const currencyList = searchList.data.map((item) => ({
        id: item.underlyingCurrencyId,
        value: item.showSymbol,
        label: item.showSymbol,
        suffixName: item.showSymbol,
        icon: item.logo,
        alt: item.logo || '',
        formatDetailPlatformsList: item?.formatDetailPlatforms || [],
        isCheck: false
      }))
      if (params?.recommend) {
        this.recommendCurrencyList = cloneDeep(currencyList)
      }
      return currencyList
    },
    /**
     * 初始化Dashboard数据
     * @param {number} entityId 主体id
     * @param {object} params 请求参数
     * @property {string} period 时间范围
     * @property {string} timeRange 时间段
     * @param {object} ledgerParams 请求参数
     * @property {string[]} dateRange 时间段
     */
    async initDashboardData(entityId: string, params: Object, ledgerParams: Object) {
      try {
        const response = await Promise.all([
          this.fetchCurrencyList({ recommend: true, entityId: 0 }),
          this.fetchTreasuryData(entityId),
          this.fetchReportsData(entityId, params),
          this.fetchLedgerData(entityId, ledgerParams)
        ])
        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取辅助核算列表
     * @param {number} entityId 主体id
     */
    async fetchAuxiliaryCalculationList(entityId: string) {
      try {
        const { data } = await LedgerApi.getAuxiliaryTypeList(entityId, { page: 1, limit: 100 })
        this.auxiliaryTypeList = data?.list || []
        return data.list
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 清空dashboardStore数据
     */
    clearDashboardData() {
      this.treasuryData = {
        fixedChartList: [] as Array<DashboardChartItemTypes>,
        sortableChartList: [] as Array<DashboardChartItemTypes>
      }
      this.reportsData = {
        fixedChartList: [] as Array<DashboardChartItemTypes>,
        sortableChartList: [] as Array<DashboardChartItemTypes>
      }
      this.ledgerData = []
      this.dashboardCurrencyList = []
      this.recommendCurrencyList = []
      this.auxiliaryTypeList = []
    }
  },
  getters: {
    getCurrentReportFilterInfo: (state) => (entityId: string) => {
      return state.reportChartsFilterInfo.find((item) => item.entityId === entityId) || null
    },
    getCurrentLedgerFilterInfo: (state) => (entityId: string) => {
      return state.ledgerChartsFilterInfo.find((item) => item.entityId === entityId) || null
    }
  },
  persist: {
    enabled: true,
    strategies: [
      {
        storage: localStorage,
        paths: ['reportChartsFilterInfo', 'ledgerChartsFilterInfo']
      }
    ]
  }
})
