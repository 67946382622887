<template>
  <div class="elv-dashboard-chart-item-screening-condition-select">
    <TextSelectFilter
      v-if="props.dashboardModuleType === DashboardModuleType.treasury.value && props.showFilterDateRange"
      v-model="timeModel"
      class="elv-dashboard-chart-item-screening-time-range-select"
      :options="timeGranularityList"
      @change="onChangeSelectTimeRange"
    />
    <TextSelectFilter
      v-if="props.dashboardModuleType === DashboardModuleType.reports.value && showViewBySelect"
      v-model="timeModel"
      class="elv-dashboard-chart-item-screening-time-range-select"
      :options="targetTypeViewByOptionsList"
      @change="onChangeSelectTimeRange"
    />
    <ElvSelect
      v-if="props.showFilterCurrency"
      v-model="currencyModel"
      :teleported="false"
      filterable
      remote
      multiple
      collapse-tags
      collapse-tags-tooltip
      remote-show-suffix
      :closableTag="false"
      :showTagIcon="false"
      :clearable="false"
      class="elv-dashboard-chart-item-screening-currency-select"
      popper-class="elv-dashboard-chart-item-screening-currency-select-popper"
      :max-collapse-tags="3"
      :loading="searchLoading"
      width="104px"
      :remote-method="remoteCurrencyMethod"
      :placeholder="t('common.input')"
      :options="currencyOptions"
      @change="onChangeSelectCurrency"
      @visible-change="changeSelectPopover"
    />
    <ChartOfAccountSelect
      v-if="props.dashboardModuleType === DashboardModuleType.ledger.value"
      v-model="coaIdsModel"
      @change="onChangeChartOfAccount"
    />
    <span
      v-if="props.dashboardModuleType === DashboardModuleType.ledger.value"
      class="elv-dashboard-chart-item-screening-select-line"
    ></span>
    <TextSelectFilter
      v-if="props.dashboardModuleType === DashboardModuleType.ledger.value"
      v-model="subGroupModel"
      class="elv-dashboard-chart-item-screening-currency-select"
      :title="`${t('common.group')}`"
      :options="subGroupOptions"
      @change="onChangeSubGroupSelect"
    />
  </div>
</template>

<script setup lang="ts">
import { useEntityStore } from '@/stores/modules/entity'
import ElvSelect from '@/components/Base/ElvSelect.vue'
import ChartOfAccountSelect from './ChartOfAccountSelect.vue'
import { useDashboardStore } from '@/stores/modules/dashboard'
import TextSelectFilter from '@/components/Base/Filter/TextSelect.vue'
import { CurrencyOptionType, DashboardCurrencyTypes } from '#/DashboardTypes'
import { DashboardModuleType, timeGranularity, viewByList } from '@/config/dashboard'

const props = defineProps({
  dashboardModuleType: {
    type: String,
    default: ''
  },
  targetType: {
    type: String,
    default: 'TREND'
  },
  currencyList: {
    type: Array as PropType<any[]>,
    default: () => []
  },
  showFilterDateRange: {
    type: Boolean,
    default: true
  },
  showFilterCurrency: {
    type: Boolean,
    default: true
  },
  showFilterViewBy: {
    type: Boolean,
    default: false
  },
  showTrend: {
    type: Boolean,
    default: false
  },
  showDistribution: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits([
  'onChangeSelectTimeRange',
  'onChangeSelectCurrency',
  'onChangeSubGroupSelect',
  'onChangeChartOfAccount'
])

const { t } = useI18n()
const route = useRoute()
const entityStore = useEntityStore()
const dashboardStore = useDashboardStore()

const searchLoading = ref(false)
const searchCurrencyKeyword = ref('')
const currencyOptions = ref([] as Array<any>)
const searchCurrencyList = ref<CurrencyOptionType[]>([])
const selectCurrencyOptions = ref<CurrencyOptionType[]>([])

const timeModel = defineModel('timeModel', { type: String, default: () => '' })
const currencyModel = defineModel('currencyModel', { type: Array, default: () => [] })
const coaIdsModel = defineModel('coaIdsModel', { type: Array, default: () => [] })
const subGroupModel = defineModel('subGroupModel', { type: String, default: () => '' })

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const timeGranularityList = computed(() => {
  return timeGranularity.map((item) => {
    return { label: t(item.label), value: item.value }
  })
})

const viewBySelectList = computed(() => {
  const periodInfo = dashboardStore.getCurrentReportFilterInfo(entityId.value)
  if (!periodInfo) return []
  if (periodInfo.period === 'YEAR') {
    return viewByList.map((item) => {
      return { label: t(item.label), value: item.value }
    })
  }
  if (periodInfo.period === 'QUARTER') {
    return [viewByList[1], viewByList[2]].map((item) => {
      return { label: t(item.label), value: item.value }
    })
  }
  return [
    {
      label: t(viewByList[2].label),
      value: viewByList[2].value
    }
  ]
})

const targetTypeViewByOptionsList = computed(() => {
  if (props.targetType === 'TREND') {
    return props.showTrend ? viewBySelectList.value : [viewByList[2]]
  }
  return props.showDistribution ? viewBySelectList.value : [viewByList[2]]
})

const showViewBySelect = computed(() => {
  if (props.targetType === 'TREND') {
    return props.showFilterViewBy || props.showTrend
  }
  // return props.showFilterViewBy || props.showDistribution
  return false
})

const subGroupOptions = computed(() => {
  let options: any = []
  if (dashboardStore.auxiliaryTypeList?.length > 0) {
    options = dashboardStore.auxiliaryTypeList.map((item: any) => {
      return {
        label: item.type === 'ENTITY_ACCOUNT' ? 'Treasury account' : item.name,
        value: item.auxiliaryTypeId,
        ...item
      }
    })
  }
  return options
})

const initOptions = () => {
  const allOptions = searchCurrencyKeyword.value
    ? [...searchCurrencyList.value]
    : [...searchCurrencyList.value, ...selectCurrencyOptions.value]
  const options = allOptions.reduce((acc: CurrencyOptionType[], current: CurrencyOptionType) => {
    const found = acc.find((item) => item.value === current.value)
    if (!found) {
      acc.push(current)
    }
    return acc
  }, [])
  const allCurrencyIndex = options.findIndex((item) => item.value === 'All_Currency')
  if (allCurrencyIndex > -1) {
    const element = options.splice(allCurrencyIndex, 1)[0]
    options.unshift(element)
  }
  options.forEach((item) => {
    item.isCheck = currencyModel.value.includes(item.value)
  })
  currencyOptions.value = options
}

/**
 * 切换科目后，剔除掉不和最新科目同属同一个一级节点的科目
 */
const filterChartOfAccountBelongSame = (coaIds: string[] = []) => {
  if (!coaIds.length) return
  const lastCoaId = coaIds[coaIds.length - 1]
  let parentChartOfAccount = null
  for (const item of entityStore.chartOfAccountOriginList) {
    for (const subItem of item.list) {
      if (subItem.chartOfAccountId === lastCoaId) {
        parentChartOfAccount = item
        break
      }
    }
    if (parentChartOfAccount) {
      break
    }
  }
  const filteredCoaIds = coaIds.filter((item) => {
    return entityStore.chartOfAccountOriginList.some((parent) => {
      return (
        parent.chartOfAccountId === parentChartOfAccount?.chartOfAccountId &&
        parent.list.some((subItem) => subItem.chartOfAccountId === item)
      )
    })
  })
  coaIdsModel.value = filteredCoaIds
}

/**
 * 切换筛选预览时间范围
 * @param timeRange 时间范围
 */
const onChangeSelectTimeRange = (timeRange: string) => {
  emit('onChangeSelectTimeRange', timeRange)
}

/**
 * 切换分组配置选择
 */
const onChangeSubGroupSelect = (subGroup: string) => {
  emit('onChangeSubGroupSelect', subGroup)
}

/**
 * 切换科目配置选择
 */
const onChangeChartOfAccount = (chartOfAccount: string[]) => {
  filterChartOfAccountBelongSame(chartOfAccount)
  emit('onChangeChartOfAccount', chartOfAccount)
}

/**
 * 初始化币种选择信息
 */

const initCurrencyModel = () => {
  selectCurrencyOptions.value = [
    {
      id: 'All_Currency',
      value: 'All_Currency',
      label: t('common.allCurrencies')
    }
  ]
}

/**
 * 更改所选的币种信息
 */
const onChangeSelectCurrency = (currency: string[]) => {
  if (currency.length) {
    if (currency[currency.length - 1] === 'All_Currency') {
      initCurrencyModel()
    } else {
      const checkedCurrencyInfo = currencyOptions.value.find((item) => item.value === currency[currency.length - 1])
      if (checkedCurrencyInfo) {
        selectCurrencyOptions.value.push(checkedCurrencyInfo)
        selectCurrencyOptions.value = selectCurrencyOptions.value.reduce(
          (acc: CurrencyOptionType[], current: CurrencyOptionType) => {
            const found = acc.find((item) => item.id === current.id)
            if (!found) {
              acc.push(current)
            }
            return acc
          },
          []
        )
      }
    }
  } else {
    initCurrencyModel()
  }
  emit('onChangeSelectCurrency', currency)
}

/**
 * 选择币种弹出框显示/隐藏
 */
const changeSelectPopover = (visible: boolean) => {
  if (!visible) {
    searchCurrencyKeyword.value = ''
    searchCurrencyList.value = dashboardStore.recommendCurrencyList
  } else {
    initOptions()
  }
}

/**
 * 远程搜索币种信息
 * @param query 币种名称
 */
const remoteCurrencyMethod = async (query: string) => {
  if (query) {
    searchCurrencyKeyword.value = query
    searchLoading.value = true
    const params: any = {
      recommend: false,
      entityId: 0,
      keywords: query ? [query] : []
    }
    try {
      searchCurrencyList.value = await dashboardStore.fetchCurrencyList(params)
      searchCurrencyList.value.unshift({
        id: 'All_Currency',
        value: 'All_Currency',
        label: t('common.allCurrencies'),
        isCheck: currencyModel.value.includes('All_Currency')
      })
      searchCurrencyList.value.forEach((item) => {
        item.isCheck = currencyModel.value.includes(item.value)
      })
      initOptions()
    } catch (error) {
      console.log(error)
    } finally {
      searchLoading.value = false
    }
  }
}

/**
 * 监听币种列表变化
 */
watch(
  () => dashboardStore.recommendCurrencyList,
  (newValue: any) => {
    if (newValue?.length) {
      searchCurrencyList.value = newValue
    }
  },
  {
    immediate: true,
    once: true
  }
)

watch(
  () => props.currencyList,
  () => {
    selectCurrencyOptions.value = props.currencyList.map((item: DashboardCurrencyTypes) => {
      return {
        id: item.currency,
        value: item.currency,
        label: item.currency,
        icon: item.underlyingCurrency?.logo || ''
      }
    })
    selectCurrencyOptions.value.unshift({
      id: 'All_Currency',
      value: 'All_Currency',
      label: t('common.allCurrencies'),
      isCheck: !props.currencyList.length
    })
    initOptions()
  },
  {
    immediate: true
  }
)
</script>

<style lang="scss">
.elv-dashboard-chart-item-screening-condition-select {
  display: flex;
  align-items: center;

  .el-select .el-select__wrapper.is-hovering:not(.is-focused, .is-disabled) {
    border: none;
    box-shadow: none;
  }

  .elv-dashboard-chart-item-screening-time-range-select {
    margin-right: 12px;
    padding-right: 0px;

    .el-select__wrapper {
      padding: 0px;
      border: none;
      box-shadow: none;
      background-color: transparent;

      .el-select__selected-item span {
        font-weight: 400;
        font-size: 12px;
        color: #636b75;
      }
    }

    .elv-base-text-select-list {
      margin-right: 0px;
    }
  }

  .elv-dashboard-chart-item-screening-currency-select {
    .elv-base-text-select-item__label {
      margin-right: 4px;
    }

    .el-select__wrapper {
      padding: 0px;
      border: none;
      box-shadow: none;
      background-color: transparent;
      gap: 0px;

      .elv-select-selected-multiple-tags {
        .el-tag {
          border: none;
          background-color: transparent;
          padding: 0px;

          .el-tag__content {
            color: #636b75;
            line-height: 15px;

            span {
              max-width: none;
            }
          }
        }
      }

      .el-select__input-wrapper {
        input {
          max-width: 12px;
        }
      }

      .el-select__suffix {
        .el-icon {
          margin-left: 0px;
        }
      }
    }
  }

  .elv-dashboard-chart-item-screening-currency-select-popper {
    width: 150px;
  }

  .elv-dashboard-chart-item-screening-select-line {
    width: 1px;
    height: 12px;
    background-color: #dde1e6;
    margin: 0px 16px;
  }
}
</style>
