<template>
  <div class="elv-dashboard-component-pie-chart-container">
    <div class="elv-dashboard-chart-item-Statistical-info">
      {{ t('common.total') }}:
      <span :title="totalAmountNumber.toNumber() + ''">{{
        fieldValueFormat(
          totalAmountNumber.toNumber(),
          {
            dollar: true,
            symbol: `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
          },
          'NUMBER'
        )
      }}</span>
    </div>
    <div class="elv-dashboard-component-pie-chart-container-panel">
      <div
        ref="pieChartRef"
        class="elv-dashboard-component-bar-pie-chart"
        :style="{ width: props.chartWidth, height: props.chartHeight }"
      ></div>
      <div class="elv-dashboard-component-pie-chart-item-container">
        <el-radio-group
          v-if="isShowNumberDisplayType"
          v-model="currentShowType"
          class="elv-dashboard-component-pie-chart-item-radio-group"
          @change="onChangeValueShowType"
        >
          <!-- <el-radio value="ALL">{{ t('common.all') }}</el-radio> -->
          <el-radio value="POSITIVE">{{ t('project.dashboard.positiveValues') }}</el-radio>
          <el-radio value="NEGATIVE">{{ t('project.dashboard.negativeValues') }}</el-radio>
        </el-radio-group>
        <el-collapse
          v-model="activeCollapse"
          accordion
          class="elv-dashboard-component-pie-chart-item-collapse"
          :class="{ 'elv-dashboard-component-pie-chart-item-collapse-small': isShowNumberDisplayType }"
        >
          <el-collapse-item v-for="(item, index) in chartItemList" :key="item.name" :name="`${index + 1}`">
            <template #title>
              <p class="elv-dashboard-component-pie-chart-item-platform-item-title">
                <span class="circle" :style="{ 'background-color': currentPieColor(index) }"></span>
                <el-tooltip
                  effect="light"
                  placement="top"
                  popper-class="elv-dashboard-component-pie-chart-collapse-child-tips"
                  :show-after="500"
                >
                  <span class="elv-dashboard-component-pie-chart-item-platform-item-name">{{
                    transformI18n(labelFormatter(item.name))
                  }}</span>
                  <template #content>
                    <p>
                      <span>{{ transformI18n(labelFormatter(item.name)) }}</span>
                      <span>{{
                        fieldValueFormat(
                          item.value,
                          {
                            dollar: true,
                            symbol: `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
                          },
                          'NUMBER'
                        )
                      }}</span>
                      <span>{{ childCollapseItemPercent(collapseParentTotalValue.toNumber(), item.value) }}%</span>
                    </p>
                  </template>
                </el-tooltip>
              </p>
              <span class="line"></span>
              <span
                class="elv-dashboard-component-pie-chart-item-platform-item-value"
                :title="
                  fieldValueFormat(
                    item.value,
                    {
                      price: true,
                      keepPoint: true,
                      symbol: `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
                    },
                    'NUMBER'
                  )
                "
                >{{
                  fieldValueFormat(
                    item.value,
                    {
                      dollar: true,
                      symbol: `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
                    },
                    'NUMBER'
                  )
                }}</span
              >
            </template>
            <p
              v-for="currencyItem in item.children"
              :key="currencyItem.name"
              class="elv-dashboard-component-pie-chart-item-currency"
            >
              <span class="elv-dashboard-component-pie-chart-item-currency-item-title">
                <span v-if="currencyItem.isOther" class="circle"></span>
                <img v-else :src="currencyItem.logo" alt="" :onerror="useDefaultImage" />
                <el-tooltip
                  placement="top"
                  effect="light"
                  popper-class="elv-dashboard-component-pie-chart-collapse-child-tips"
                  :show-after="500"
                >
                  <span class="elv-dashboard-component-pie-chart-item-currency-item-name">{{ currencyItem.name }}</span>
                  <template #content>
                    <p>
                      <span>{{ currencyItem.name }}</span>
                      <span>{{
                        fieldValueFormat(
                          currencyItem.value,
                          {
                            dollar: true,
                            symbol: `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
                          },
                          'NUMBER'
                        )
                      }}</span>
                      <span>{{ childCollapseItemPercent(item.value, currencyItem.value) }}%</span>
                    </p>
                  </template>
                </el-tooltip>
              </span>
              <span class="line"></span>
              <span
                class="elv-dashboard-component-pie-chart-item-currency-item-value"
                :title="
                  fieldValueFormat(
                    currencyItem.value,
                    {
                      price: true,
                      keepPoint: true,
                      symbol: `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
                    },
                    'NUMBER'
                  )
                "
                >{{
                  fieldValueFormat(
                    currencyItem.value,
                    {
                      dollar: true,
                      symbol: `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
                    },
                    'NUMBER'
                  )
                }}</span
              >
            </p>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Big from 'big.js'
import echarts from '@/plugins/echarts'
import { find, isEmpty } from 'lodash-es'
import { ref, onBeforeUnmount } from 'vue'
import { transformI18n } from '@/i18n/index'
import { transactionType } from '@/config/index'
import defaultImg from '@/assets/icons/submit-icon.svg'
import { useEntityStore } from '@/stores/modules/entity'
import { DashboardChartItemTypes } from '#/DashboardTypes'
import { DashboardChartColorType } from '@/config/dashboard'
import { fieldValueFormat, formatNumberToSignificantDigits, generateColorShades } from '@/lib/utils'

type CharItemType = {
  name: string
  value: number
  totalAmountFC: number
  children: { name: string; value: number; totalAmountFC: number; logo: string; isOther: boolean }[]
}

const { t } = useI18n()
const entityStore = useEntityStore()
const { entityDetail } = storeToRefs(entityStore)
const pieChartRef = useTemplateRef('pieChartRef')

let chartInstance: any = null
const activeCollapse = ref('1')
const currentShowType = ref('POSITIVE') // ALL | POSITIVE | NEGATIVE
const chartItemList = ref<Array<CharItemType>>([])

const props = defineProps({
  data: {
    type: Object as () => DashboardChartItemTypes | null,
    required: true
  },
  chartWidth: {
    type: String,
    default: '240px'
  },
  chartHeight: {
    type: String,
    default: '240px'
  },
  timeRange: {
    type: String,
    required: true
  }
})

const collapseParentTotalValue = computed(() => {
  return chartItemList.value.reduce((acc, cur) => {
    return acc.plus(Math.abs(cur.value))
  }, Big(0))
})

const currentPieColor = computed(() => (index: number) => {
  const colorIndex = index % DashboardChartColorType.length
  return DashboardChartColorType[colorIndex] ? DashboardChartColorType[colorIndex] : ''
})

const isShowNumberDisplayType = computed(() => {
  if (props.data && Array.isArray(props.data.chartValueList)) {
    const hasPositive = props.data?.chartValueList.some(
      (chartItem) => !Number.isNaN(chartItem.totalAmountFC) && chartItem.totalAmountFC > 0
    )
    const hasNegative = props.data?.chartValueList.some(
      (chartItem) => !Number.isNaN(chartItem.totalAmountFC) && chartItem.totalAmountFC < 0
    )
    return hasPositive && hasNegative
  }
  return false
})

const totalAmountNumber = computed(() => {
  return chartItemList.value.reduce((acc, cur) => {
    return acc.plus(cur.value)
  }, Big(0))
})

const labelFormatter = (label: string): string => {
  let title = label
  if (find(transactionType, { value: label })) {
    title = find(transactionType, { value: label })?.label || ''
  }
  return title
}

const childCollapseItemPercent = (total: number, value: number) => {
  const percent = (Math.abs(value) / Math.abs(total)) * 100
  return formatNumberToSignificantDigits(percent, 2, '', false)
}

const findParentNode = (data: Array<any>, target: any) => {
  if (!data) return null
  for (const item of data) {
    if (item?.children) {
      for (const child of item.children) {
        if (
          child.name === target.name &&
          (child?.value === target?.value || child?.totalAmountFC === target?.totalAmountFC)
        ) {
          return item
        }
      }
      const result: any = findParentNode(item.children, target)
      if (result) {
        return result
      }
    }
  }
  return null
}

const useDefaultImage = (event: any) => {
  event.target.src = defaultImg
}

const filterShowList = () => {
  if (isShowNumberDisplayType.value) {
    if (currentShowType.value === 'POSITIVE') {
      return (
        props.data?.chartValueList.filter((item) => !Number.isNaN(item.totalAmountFC) && item.totalAmountFC > 0) || []
      )
    }
    if (currentShowType.value === 'NEGATIVE') {
      return (
        props.data?.chartValueList.filter((item) => !Number.isNaN(item.totalAmountFC) && item.totalAmountFC < 0) || []
      )
    }
  }
  return props.data?.chartValueList || []
}

const resetChartData = () => {
  if (props.data && Array.isArray(props.data.chartValueList)) {
    const currentShowList = filterShowList()
    const list: CharItemType[] = currentShowList.map((item) => {
      let templateName = item.others ? t('common.others') : item.platformType || item.chartOfAccount?.name || ''
      if (!templateName) {
        if (item?.auxiliaryValue) {
          if (!isEmpty(item?.auxiliaryValue?.entityAccount)) {
            templateName = item?.auxiliaryValue?.entityAccount?.isUnnamed
              ? t('common.uncategorized')
              : item?.auxiliaryValue?.entityAccount?.name || ''
          } else if (!isEmpty(item?.auxiliaryValue?.counterparty)) {
            templateName = item?.auxiliaryValue?.counterparty?.isUnnamed
              ? t('common.uncategorized')
              : item?.auxiliaryValue?.counterparty?.name || ''
          } else if (!isEmpty(item?.auxiliaryValue?.auxiliaryItem)) {
            templateName = item?.auxiliaryValue?.auxiliaryItem?.isUnnamed
              ? t('common.uncategorized')
              : item?.auxiliaryValue?.auxiliaryItem?.value || ''
          }
        }
      }
      return {
        name: templateName,
        value: item.totalAmountFC,
        totalAmountFC: item?.currencyList
          ? item?.currencyList
              .reduce((acc: Big, cur: any) => {
                const value = cur.totalAmountFC !== null && cur.totalAmountFC !== '0' ? Math.abs(cur.totalAmountFC) : 0
                return acc.plus(Math.abs(value))
              }, Big(0))
              .toNumber()
          : Math.abs(item?.totalAmountFC) || 0,
        children: Array.isArray(item?.currencyList)
          ? item.currencyList.map((currencyItem) => {
              return {
                name: currencyItem?.others ? t('common.others') : currencyItem.currency,
                totalAmountFC: Math.abs(currencyItem.totalAmountFC),
                value: currencyItem.totalAmountFC,
                logo: currencyItem?.underlyingCurrency?.logo || defaultImg,
                isOther: currencyItem?.others || false
              }
            })
          : []
      }
    })
    chartItemList.value = list
  } else {
    chartItemList.value = []
  }
}

const initChart = () => {
  if (!pieChartRef.value) return
  chartInstance?.dispose(pieChartRef.value)
  chartInstance = echarts.init(pieChartRef.value, {
    width: props.chartWidth,
    height: props.chartHeight
  })
  const isHasChildren = chartItemList.value.some((item) => item.children?.length > 0)
  const option = {
    legend: {
      orient: 'vertical',
      right: 10,
      top: 'center',
      itemGap: 8,
      icon: 'circle',
      textStyle: {
        color: '#1E2024',
        fontWeight: 400,
        fontFamily: 'Plus Jakarta Sans',
        fontSize: 11,
        lineHeight: 14,
        overflow: 'truncate'
      },
      selectedMode: true,
      data: props.data
    },
    tooltip: {
      trigger: 'item',
      padding: 12,
      borderWidth: 0,
      position: 'right',
      formatter: (params: any) => {
        let percent = ''
        if (params.seriesName === '内层') {
          percent = `${params.percent}%`
        } else {
          const parentNode = findParentNode(chartItemList.value, params.data)
          percent = parentNode ? `${childCollapseItemPercent(parentNode.value, params.data.value)}%` : ''
        }
        return `<p><span>${transformI18n(labelFormatter(params?.data?.name))}</span><span>${fieldValueFormat(
          params?.data?.totalAmountFC,
          {
            dollar: true,
            symbol: `${
              entityDetail.value?.underlyingCurrency?.sign || `${entityDetail.value?.underlyingCurrency?.showSymbol} `
            }`
          },
          'NUMBER'
        )}</span><span>${percent}</span></p>`
      },
      className: 'elv-dashboard-component-pie-chart-collapse-child-tips'
    },
    series: isHasChildren
      ? [
          {
            name: '内层',
            type: 'pie',
            radius: ['28%', '58%'],
            colorBy: 'data',
            top: 'middle',
            data: chartItemList.value.map((item, index) => ({
              name: item.name,
              value: item.totalAmountFC,
              totalAmountFC: item.value,
              label: {
                show: true,
                position: 'inside',
                formatter: `{d}%`,
                color: '#1E2024',
                fontWeight: 500,
                fontSize: 12,
                fontFamily: 'Barlow',
                textBorderColor: '#FFFFFF',
                textBorderWidth: 3,
                overflow: 'truncate'
              },
              itemStyle: {
                color: DashboardChartColorType[index % DashboardChartColorType.length],
                borderColor: '#fff',
                borderWidth: 0.2
              }
            }))
            // minAngle: 2
          },
          {
            name: '外层',
            type: 'pie',
            radius: ['58%', '90%'],
            colorBy: 'data',
            top: 'middle',
            data: chartItemList.value.flatMap((item, chartIndex) =>
              item.children.map((child, index) => ({
                name: child.name,
                value: child.totalAmountFC,
                totalAmountFC: child.value,
                label: {
                  show: false
                },
                itemStyle: {
                  color: generateColorShades(
                    DashboardChartColorType[chartIndex % DashboardChartColorType.length],
                    item.children.length + 1
                  )[index + 1],
                  borderColor: '#fff',
                  borderWidth: 0.2
                }
              }))
            )
            // minAngle: 2
          }
        ]
      : [
          {
            name: '内层',
            type: 'pie',
            radius: ['50%', '90%'],
            colorBy: 'data',
            top: 'middle',
            data: chartItemList.value.map((item, index) => ({
              name: item.name,
              value: item.totalAmountFC,
              totalAmountFC: item.value,
              label: {
                show: true,
                position: 'inside',
                formatter: `{d}%`,
                color: '#1E2024',
                fontWeight: 500,
                fontFamily: 'Barlow',
                textBorderColor: '#FFFFFF',
                textBorderWidth: 1,
                overflow: 'truncate'
              },
              itemStyle: {
                color: DashboardChartColorType[index % DashboardChartColorType.length],
                borderColor: '#fff',
                borderWidth: 0.2
              }
            }))
            // minAngle: 2
          }
        ]
  }
  if (chartInstance) {
    chartInstance.clear()
  }
  chartInstance.setOption(option)
}

const onChangeValueShowType = () => {
  resetChartData()
  nextTick(() => {
    initChart()
  })
}

watch(
  () => props.data,
  () => {
    currentShowType.value = 'POSITIVE'
    resetChartData()
    nextTick(() => {
      initChart()
    })
  },
  { immediate: true, deep: true }
)

onBeforeUnmount(() => {
  if (chartInstance) {
    chartInstance.dispose()
  }
})
</script>

<style lang="scss">
.elv-dashboard-component-pie-chart-container {
  height: 290px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .elv-dashboard-chart-item-Statistical-info {
    width: 100%;
    margin-bottom: 12px;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-weight: 400;
    color: #636b75;

    span {
      font-weight: 500;
      color: #1e2024;
    }
  }

  .elv-dashboard-component-pie-chart-container-panel {
    display: flex;
    align-items: center;
    position: relative;

    .elv-dashboard-component-pie-chart-item-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      height: 100%;
      position: relative;
      margin-left: 40px;
    }
  }

  .elv-dashboard-component-pie-chart-item-radio-group {
    width: 100%;
    margin-bottom: 15px;
    display: flex;
    gap: 24px;
    flex-wrap: nowrap;
    overflow: auto;

    .el-radio {
      margin-right: 0px;

      .el-radio__input.is-checked .el-radio__inner {
        background: #1753eb;
        background-color: #1753eb;
      }

      .el-radio__label {
        font-family: 'Plus Jakarta Sans';
        font-size: 12px;
        font-weight: 400;
        padding-left: 4px;
        color: #1e2024;
      }

      &:last-child {
        margin-right: 0px;
      }

      &.is-checked {
        .el-radio__label {
          color: #1e2024;
        }
      }
    }
  }

  .elv-dashboard-component-pie-chart-item-collapse {
    min-width: 210px;
    max-width: 250px;
    flex: 1;
    min-height: 0;
    border: none;
    box-shadow: none;
    max-height: 230px;
    overflow: auto;

    &-small {
      max-height: 190px;
    }

    .el-collapse-item {
      margin-bottom: 10px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding-right: 10px;

      button {
        background-color: transparent;
        border: none;
        box-shadow: none;
        display: flex;
        align-items: center;
        height: 14px;

        .el-collapse-item__arrow {
          display: none;
        }

        .elv-dashboard-component-pie-chart-item-platform-item-title {
          display: flex;
          align-items: center;
          font-family: 'Plus Jakarta Sans';
          font-weight: 600;
          font-size: 11px;
          color: #1e2024;
          line-height: 14px;

          .circle {
            display: block;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            margin-right: 4px;
          }

          .elv-dashboard-component-pie-chart-item-platform-item-name {
            max-width: 120px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .line {
          flex: 1;
          height: 0.5px;
          background-color: #dde1e6;
          margin: 0px 8px;
        }

        .elv-dashboard-component-pie-chart-item-platform-item-value {
          font-family: 'Barlow';
          font-weight: 400;
          font-size: 11px;
          color: #1e2024;
          line-height: 14px;
        }
      }

      .el-collapse-item__wrap {
        background-color: transparent;
        border: none;
        width: calc(100% - 16px);

        .el-collapse-item__content {
          padding-bottom: 0px;
        }

        .elv-dashboard-component-pie-chart-item-currency {
          display: flex;
          align-items: center;
          margin-top: 8px;

          .elv-dashboard-component-pie-chart-item-currency-item-title {
            display: flex;
            align-items: center;
            font-family: 'Plus Jakarta Sans';
            font-weight: 400;
            font-size: 11px;

            img {
              width: 10px;
              height: 10px;
              border-radius: 50%;
              margin-right: 2px;
            }

            .circle {
              display: block;
              width: 10px;
              height: 10px;
              border-radius: 50%;
              margin-right: 2px;
              background-color: #dde1e6;
            }
          }

          .line {
            flex: 1;
            height: 0.5px;
            background-color: #dde1e6;
            margin: 0px 8px;
          }

          .elv-dashboard-component-pie-chart-item-currency-item-value {
            font-family: 'Barlow';
            font-weight: 400;
            font-size: 11px;
            color: #1e2024;
            line-height: 14px;
          }
        }
      }
    }
  }
}

.elv-dashboard-component-pie-chart-collapse-child-tips {
  max-height: 43px;
  display: flex;
  align-items: center;
  padding: 0px 12px !important;
  border: none;
  box-shadow:
    0px 1px 0px 0px rgba(0, 0, 0, 0.05),
    0px 4px 10px 0px rgba(0, 0, 0, 0.1);

  p {
    font-family: 'Barlow';
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    display: flex;
    align-items: center;
    height: 42px;

    span {
      &:first-child {
        font-weight: 500;
        margin-right: 24px;
      }

      &:last-child {
        margin-left: 8px;
      }
    }
  }
}
</style>
