<template>
  <div class="elv-dashboard-component-horizontal-bar-chart-container">
    <div class="elv-dashboard-chart-item-Statistical-info">
      {{ t('common.total') }}:
      <span :title="totalAmountNumber.toNumber() + ''">{{
        fieldValueFormat(
          totalAmountNumber.toNumber(),
          {
            dollar: true,
            symbol: `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
          },
          'NUMBER'
        )
      }}</span>
    </div>
    <div class="elv-dashboard-component-horizontal-bar-chart-container-panel">
      <div
        ref="horizontalBarChartRef"
        class="elv-dashboard-component-horizontal-bar-chart"
        :style="{ width: props.chartWidth, height: props.chartHeight }"
      ></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Big from 'big.js'
import echarts from '@/plugins/echarts'
import { find, isEmpty } from 'lodash-es'
import { transformI18n } from '@/i18n/index'
import { transactionType } from '@/config/index'
import { useEntityStore } from '@/stores/modules/entity'
import { DashboardChartItemTypes } from '#/DashboardTypes'
import { DashboardChartColorType } from '@/config/dashboard'
import { fieldValueFormat, generateColorShades } from '@/lib/utils'

type CharItemType = {
  name: string
  value: number
  children: { name: string; parentName: string; value: number; logo: string; isOther: boolean }[]
}

const props = defineProps({
  data: {
    type: Object as () => DashboardChartItemTypes | null,
    required: true
  },
  chartWidth: {
    type: String,
    default: '100%'
  },
  chartHeight: {
    type: String,
    default: '290px'
  },
  timeRange: {
    type: String,
    required: true
  }
})

let chartInstance: any = null
const horizontalBarChartRef = useTemplateRef('horizontalBarChartRef')
const chartItemList = ref<Array<CharItemType>>([])
const seriesList = ref<any>([])

const { t } = useI18n()
const entityStore = useEntityStore()
const { entityDetail } = storeToRefs(entityStore)

const totalAmountNumber = computed(() => {
  return chartItemList.value.reduce((acc, cur) => {
    return acc.plus(cur.value)
  }, Big(0))
})

/**
 * @description: 计算子项总和
 * @param {*} children
 * @return {*}
 */
const valueTotalFormatter = (children: any) => {
  const total = children.reduce((childrenAcc: Big, childrenCur: any) => {
    const childrenValue = childrenCur.value !== null && childrenCur.value !== '0' ? childrenCur.value : 0
    return childrenAcc.plus(Math.abs(childrenValue))
  }, Big(0))
  return total || Big(0)
}

const totalValue = computed(() => {
  return chartItemList.value.reduce((acc: Big, cur: any) => {
    const totalAmountFC = cur.value !== null && cur.value !== '0' ? cur.value : 0
    // eslint-disable-next-line no-use-before-define
    const childrenValueTotal = cur.children?.length ? valueTotalFormatter(cur.children) : Big(totalAmountFC)
    return acc.plus(childrenValueTotal)
  }, Big(0))
})

/**
 * @description: x轴label格式化
 * @param {string} label
 * @return {string}
 */
const labelFormatter = (label: string): string => {
  let title = label
  if (find(transactionType, { value: label })) {
    title = find(transactionType, { value: label })?.label || ''
  }
  return title
}

/**
 * @description: 重置图表数据
 */
const resetChartData = () => {
  if (props.data && Array.isArray(props.data.chartValueList)) {
    let list: CharItemType[] = props.data.chartValueList.map((item) => {
      let templateName = item.others ? t('common.others') : item.platformType || item.chartOfAccount?.name || ''
      if (!templateName) {
        if (item?.auxiliaryValue) {
          if (!isEmpty(item?.auxiliaryValue?.entityAccount)) {
            templateName = item?.auxiliaryValue?.entityAccount?.isUnnamed
              ? t('common.uncategorized')
              : item?.auxiliaryValue?.entityAccount?.name || ''
          } else if (!isEmpty(item?.auxiliaryValue?.counterparty)) {
            templateName = item?.auxiliaryValue?.counterparty?.isUnnamed
              ? t('common.uncategorized')
              : item?.auxiliaryValue?.counterparty?.name || ''
          } else if (!isEmpty(item?.auxiliaryValue?.auxiliaryItem)) {
            templateName = item?.auxiliaryValue?.auxiliaryItem?.isUnnamed
              ? t('common.uncategorized')
              : item?.auxiliaryValue?.auxiliaryItem?.value || ''
          }
        }
      }
      return {
        name: templateName,
        value: item.totalAmountFC,
        children: Array.isArray(item.currencyList)
          ? item.currencyList.map((currencyItem) => {
              return {
                name: currencyItem?.others ? t('common.others') : currencyItem.currency,
                parentName: item.platformType,
                value: currencyItem.totalAmountFC,
                logo: currencyItem.underlyingCurrency?.logo || '',
                isOther: currencyItem?.others || false
              }
            })
          : []
      }
    })
    list = list.reverse()
    chartItemList.value = list
    const childrenList = list.flatMap((item) => item.children)
    seriesList.value = []
    if (childrenList.length) {
      const childrenColorList = generateColorShades(DashboardChartColorType[0], childrenList.length)
      childrenList.forEach((childItem) => {
        const currencyItem = seriesList.value.find((item: any) => item.name === childItem.name)
        if (!currencyItem) {
          const platValue = list.map((chartItem) => {
            let currentCurrencyIndex = chartItem.children.findIndex((item) => item.name === childItem.name)
            if (currentCurrencyIndex === -1) {
              currentCurrencyIndex = 0
            }
            return {
              name: childItem.name,
              value: chartItem.children.find((item) => item.name === childItem.name)?.value || 0,
              itemStyle: {
                color: ''
              }
            }
          })
          seriesList.value.push({
            name: childItem.name,
            type: 'bar',
            stack: 'total',
            label: {
              show: true,
              position: 'right',
              formatter: (params: any) => {
                const isLastSeries = params.seriesIndex === seriesList.value.length - 1
                if (isLastSeries) {
                  const totalAmountFC =
                    chartItemList.value?.[params?.dataIndex]?.value !== null &&
                    chartItemList.value?.[params?.dataIndex]?.value !== 0
                      ? chartItemList.value?.[params?.dataIndex].value
                      : 0
                  const childrenValueTotal = chartItemList.value?.[params?.dataIndex]?.children?.length
                    ? valueTotalFormatter(chartItemList.value?.[params?.dataIndex]?.children ?? [])
                    : Big(Math.abs(totalAmountFC))
                  if (childrenValueTotal.eq(0)) {
                    return '0'
                  }
                  const percentage = Big(childrenValueTotal).div(totalValue.value).times(100).toFixed(0)
                  return `${percentage}%`
                }
                return ''
              }
            },
            emphasis: {
              focus: 'series'
            },
            itemStyle: {
              color: ''
            },
            barMinHeight: 5,
            barWidth: 12,
            data: platValue,
            maxValue: Math.max(...platValue.map((item) => item.value)),
            isOther: childItem?.isOther || false
          })
        }
      })
      seriesList.value.sort((a: any, b: any) => b.maxValue - a.maxValue)
      seriesList.value.forEach((item: any, index: number) => {
        item.itemStyle.color = childrenColorList[index]
        item.data = item.data.map((dataItem: any) => {
          return {
            ...dataItem,
            itemStyle: {
              ...dataItem.itemStyle,
              color: childrenColorList[index]
            }
          }
        })
      })
    } else {
      seriesList.value.push({
        name: '单层图表',
        type: 'bar',
        stack: 'total',
        label: {
          show: true,
          position: 'right',
          formatter: (params: any) => {
            const isLastSeries = params.seriesIndex === seriesList.value.length - 1
            if (isLastSeries) {
              const totalAmountFC =
                chartItemList.value?.[params?.dataIndex]?.value !== null &&
                chartItemList.value?.[params?.dataIndex]?.value !== 0
                  ? chartItemList.value?.[params?.dataIndex].value
                  : 0
              const childrenValueTotal = chartItemList.value?.[params?.dataIndex]?.children?.length
                ? valueTotalFormatter(chartItemList.value?.[params?.dataIndex]?.children ?? [])
                : Big(Math.abs(totalAmountFC))
              if (childrenValueTotal.eq(0)) {
                return '0'
              }
              const percentage = Big(childrenValueTotal).div(totalValue.value).times(100).toFixed(0)
              return `${percentage}%`
            }
            return ''
          }
        },
        emphasis: {
          focus: 'series'
        },
        itemStyle: {
          color: DashboardChartColorType[0]
        },
        barWidth: 12,
        data: list.map((chartItem: CharItemType) => {
          return {
            name: chartItem.name,
            value: chartItem.value
          }
        })
      })
    }
  } else {
    chartItemList.value = []
    seriesList.value = []
  }
}

const initChart = () => {
  if (!horizontalBarChartRef.value) return
  chartInstance?.dispose(horizontalBarChartRef.value)
  chartInstance = echarts.init(horizontalBarChartRef.value)
  window.addEventListener('resize', () => {
    if (chartInstance && !chartInstance?.isDisposed()) {
      chartInstance.resize()
    }
  })
  const dashboardHorizontalOption = {
    tooltip: {
      trigger: 'axis',
      enterable: true,
      axisPointer: {
        type: 'shadow'
      },
      formatter: (params: any) => {
        let tooltip = '<div class="elv-dashboard-chart-tooltip__list">'
        params.forEach((param: any, i: number) => {
          if (i === 0) {
            let percentage = ''
            const childrenValueTotal = chartItemList.value?.[param?.dataIndex]?.children?.length
              ? valueTotalFormatter(chartItemList.value?.[param?.dataIndex]?.children ?? [])
              : Big(Math.abs(chartItemList.value?.[param?.dataIndex]?.value))
            if (childrenValueTotal.eq(0)) {
              percentage = '0'
            } else {
              percentage = Big(childrenValueTotal)
                ?.div(totalValue.value ?? '0')
                ?.times(100)
                ?.toFixed(0)
            }
            tooltip += `<div class="elv-dashboard-chart-tooltip__title"><p>${transformI18n(labelFormatter(chartItemList.value?.[param.dataIndex]?.name))}</p><span>${percentage}%</span><span>${fieldValueFormat(
              chartItemList.value?.[param?.dataIndex]?.value ?? 0,
              {
                dollar: true,
                symbol: `${
                  entityDetail.value?.underlyingCurrency?.sign ||
                  `${entityDetail.value?.underlyingCurrency?.showSymbol} `
                }`
              },
              'NUMBER'
            )}</span></div>`
          }
          if (chartItemList.value?.[param.dataIndex]?.children?.length) {
            chartItemList.value?.[param.dataIndex]?.children?.forEach((item, index: number) => {
              if (param.seriesIndex === index) {
                tooltip += `<div class="elv-dashboard-chart-tooltip__item"><p>${item?.logo ? `<img src="${item.logo}" alt="${item.name}" class="elv-dashboard-chart-tooltip__item-logo">` : `<span class="elv-dashboard-chart-tooltip__item-other"></span>`}<span>${item?.name}</span></p><p>${fieldValueFormat(
                  item?.value,
                  {
                    dollar: true,
                    symbol: `${
                      entityDetail.value?.underlyingCurrency?.sign ||
                      `${entityDetail.value?.underlyingCurrency?.showSymbol} `
                    }`
                  },
                  'NUMBER'
                )}</p></div>`
              }
            })
          } else {
            tooltip += `<div class="elv-dashboard-chart-tooltip__item"><p>${param.marker}<span>${param?.name}</span></p><p>${fieldValueFormat(
              param?.value,
              {
                dollar: true,
                symbol: `${
                  entityDetail.value?.underlyingCurrency?.sign ||
                  `${entityDetail.value?.underlyingCurrency?.showSymbol} `
                }`
              },
              'NUMBER'
            )}</p></div>`
          }
        })
        tooltip += `</div>`
        return tooltip
      },
      className: 'elv-dashboard-chart-tooltip'
    },
    grid: {
      left: '0',
      right: '6%',
      top: '2%',
      bottom: '3%',
      containLabel: true // 使得图表区域自动适应标签
    },
    xAxis: {
      type: 'value',
      axisLabel: {
        color: '#838D95',
        fontFamily: 'Barlow,',
        fontSize: 11,
        overflow: 'truncate',
        formatter: (value: number) => {
          return `${fieldValueFormat(
            value,
            {
              dollar: true,
              symbol: `${
                entityDetail.value?.underlyingCurrency?.sign || `${entityDetail.value?.underlyingCurrency?.showSymbol} `
              }`
            },
            'NUMBER'
          )}`
        }
      }
    },
    yAxis: {
      type: 'category',
      axisLabel: {
        color: '#838D95',
        fontFamily: 'Barlow,',
        fontSize: 10,
        width: 150,
        overflow: 'truncate',
        formatter: (value: string) => {
          return `${transformI18n(labelFormatter(value))}`
        }
      },
      axisLine: {
        show: false // 不显示坐标轴线
      },
      axisTick: { show: false }, // 不显示坐标轴刻度线
      data: chartItemList.value.map((item) => item.name)
    },
    dataZoom: [
      {
        yAxisIndex: [0, 1],
        type: 'slider',
        filterMode: 'empty',
        show: chartItemList.value.length > 10,
        start: 0,
        startValue: 0,
        endValue: 9,
        width: 7,
        height: '84%',
        top: 15,
        right: 2,
        fillerColor: '#DDE1E6',
        borderColor: '#DDE1E6',
        backgroundColor: 'transparent',
        handleSize: 0,
        showDataShadow: false,
        showDetail: false,
        zoomLock: true,
        realtime: true,
        brushSelect: false
      },
      {
        type: 'inside',
        minValueSpan: 10,
        yAxisIndex: [0],
        zoomOnMouseWheel: false,
        moveOnMouseWheel: true,
        moveOnMouseMove: true
      }
    ],
    series: seriesList.value
  }
  if (chartInstance) {
    chartInstance?.clear()
  }
  chartInstance.setOption(dashboardHorizontalOption)
}

watch(
  () => props.data,
  () => {
    resetChartData()
    nextTick(() => {
      initChart()
    })
  },
  { immediate: true, deep: true }
)

onBeforeUnmount(() => {
  if (chartInstance) {
    chartInstance.dispose()
  }
})
</script>

<style lang="scss">
.elv-dashboard-component-horizontal-bar-chart-container {
  height: 255px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .elv-dashboard-chart-item-Statistical-info {
    width: 100%;
    margin-bottom: 12px;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-weight: 400;
    color: #636b75;

    span {
      font-weight: 500;
      color: #1e2024;
    }
  }

  .elv-dashboard-component-horizontal-bar-chart-container-panel {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
  }
}
</style>
