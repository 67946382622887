<template>
  <div class="elv-dashboard-header-left-change-position-buttons">
    <SvgIcon
      name="caret--up"
      width="12"
      height="12"
      :class="{ 'elv-dashboard-header-left-change-position-button-disabled': props.disabledMoveUp }"
      :fill="props.disabledMoveUp ? '#D0D4D9' : '#636B75'"
      @click="moveUpModule"
    />
    <SvgIcon
      name="caret--up"
      width="12"
      height="12"
      :class="{ 'elv-dashboard-header-left-change-position-button-disabled': props.disabledMoveDown }"
      :fill="props.disabledMoveDown ? '#D0D4D9' : '#636B75'"
      @click="moveDownModule"
    />
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  disabledMoveUp: boolean
  disabledMoveDown: boolean
  moveUpModule: () => void
  moveDownModule: () => void
}>()
</script>

<style lang="scss" scoped>
.elv-dashboard-header-left-change-position-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 4px;
  top: 50%;
  transform: translateY(-55%);
  z-index: 1;

  svg {
    cursor: pointer;

    &:hover {
      border-radius: 2px;
      background-color: #edf0f3;
    }

    &:last-child {
      transform: rotate(180deg);
    }

    &.elv-dashboard-header-left-change-position-button-disabled {
      cursor: not-allowed;

      &:hover {
        background-color: transparent;
      }
    }
  }
}
</style>
